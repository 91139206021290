import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Signin() {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: Handle form submission
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="my-5 d-flex justify-content-center align-items-center">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Sign In
        </Button>
        <div className="mt-2">
          Don't have an account yet?{" "}
          <Link to="/signup" className="text-primary">
            Sign up here
          </Link>
          .
        </div>
      </Form>
    </div>
  );
}

export default Signin;

