import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3>DedEth</h3>
            <p>Email: info@companyname.com</p>
          </div>
          <div className="col-md-6">
            <h3>Links</h3>
            <ul>
              <li><a href="https://twitter.com/dedohwale">Twitter</a></li>
            </ul>
          </div>
        </div>
        <hr />
        <p>&copy; 2023 Company Name. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;

