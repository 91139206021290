import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import logo from "../logo.png";
import "./Navigation.css";

function Navigation() {
  const chapters = [
    { id: 1, name: "Chapter 1", pdf: "/I.\ Introduction.pdf" },
    { id: 2, name: "Chapter 2", pdf: "/II.\ EVM.pdf" },
    { id: 3, name: "Chapter 3", pdf: "/III.\ Accounts.pdf" },
    { id: 4, name: "Chapter 4", pdf: "/IV.\ Transactions.pdf" },
    { id: 5, name: "Chapter 5", pdf: "/V.\ Solidity\ Data\ Types.pdf" },
    { id: 6, name: "Chapter 6", pdf: "/VI.\ Smart\ Contract\ Development.pdf" },
    { id: 7, name: "Chapter 7", pdf: "/VII.\ Solidity\ Security\ Best\ Practices.pdf" },
    { id: 8, name: "Chapter 8", pdf: "/chapter8.pdf" },
    { id: 9, name: "Chapter 9", pdf: "/chapter9.pdf" },
    { id: 10, name: "Chapter 10", pdf: "/chapter10.pdf" },
  ];

  const handleChapterClick = (pdf) => {
    window.open(pdf, "_blank");
  };

  const chapterLinks = chapters.map((chapter) => (
    <NavDropdown.Item
      key={chapter.id}
      onClick={() => handleChapterClick(chapter.pdf)}
      style={{ color: "#2d4a6c" }}
    >
    {chapter.name}
    </NavDropdown.Item>
  ));

  return (
    <Navbar expand="lg" className="mx-auto align-items-center px-5" style={{ backgroundColor: "#161524" }}>
      <Navbar.Brand>
        <Link to="/">
          <img src={logo} alt="DedEth" className="logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="d-flex justify-content-end">
        <Nav>
          <NavDropdown className="nav-dropdown" title="Book">
            <Link to="" className="dropdown-item">
              Book Outline
            </Link>
            <NavDropdown.Divider />
            {chapterLinks}
          </NavDropdown>
        </Nav>
        <Nav>
                  </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;


