import React, { useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

const LeftNavScroll = () => {
  const [selectedSection, setSelectedSection] = useState(0);

  const handleSectionClick = (index) => {
    setSelectedSection(index);
  };

  const content = [
    {
      heading: "Section 1",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac faucibus felis, eget sodales libero. Sed at erat sem. Proin pharetra fermentum ipsum, a luctus sapien iaculis nec. Fusce vel aliquam elit. Vivamus eu diam est. Donec vitae euismod justo. Mauris quis magna a mauris volutpat hendrerit id nec mauris. Ut consequat vel massa eget viverra. Nunc id ante et augue sagittis sodales. Morbi vulputate massa eget velit laoreet, at consectetur ipsum ultrices.",
    },
    {
      heading: "Section 2",
      text: "Sed vel ligula mi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec sit amet metus non felis molestie efficitur quis non justo. Curabitur auctor massa non lacus imperdiet, vitae aliquam quam facilisis. Morbi hendrerit elit eget sem fermentum laoreet. Nam feugiat, arcu ac consequat malesuada, lacus urna posuere lorem, a lobortis justo erat ac elit. Sed maximus fermentum dui, in varius felis faucibus eu. Etiam imperdiet enim ac tortor rutrum, sed posuere enim auctor. Nam laoreet odio quis eros lobortis, vel molestie nisi sollicitudin. Curabitur quis turpis id lacus tincidunt euismod.",
    },
    {
      heading: "Section 3",
      text: "Nullam finibus justo sed nibh feugiat, vel ullamcorper dolor luctus. Integer vitae sapien lacinia, fermentum nunc ac, dignissim enim. Vestibulum dignissim erat eget felis dignissim laoreet. Praesent vitae mi ut libero consectetur maximus ac at quam. Sed ullamcorper risus in ligula lobortis, ac fringilla tellus laoreet. Aenean ullamcorper nunc vitae arcu eleifend euismod. Nunc ut lacus nisi. Morbi euismod magna dolor, a consectetur turpis semper et. Integer volutpat euismod enim in maximus. Suspendisse potenti.",
    },
  ];

  const rowStyle = {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  };

  const columnStyle = {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  };


  return (
    <Row style={rowStyle}>
      <Col xs={3} style={columnStyle}>
        <ListGroup>
          {content.map((section, index) => (
            <ListGroup.Item
              key={index}
              active={selectedSection === index}
              onClick={() => handleSectionClick(index)}
            >
              {section.heading}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
      <Col xs={9} style={columnStyle}>
        <div>
          <h1>{content[selectedSection].heading}</h1>
          <p>{content[selectedSection].text}</p>
        </div>
      </Col>
  </Row>
  );
};

export default LeftNavScroll;
