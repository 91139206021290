import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./components/Home";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import CourseOutline from "./components/CourseOutline";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
//import ProductList from './components/ProductList';
//import Cart from './components/Cart';
//import Checkout from './components/Checkout';
//import CartContext from './components/CartContext.js';

function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course/outline" element={<CourseOutline />} />
		  <Route path="/signup" element={<SignUp />} />
		  <Route path="/signin" element={<SignIn />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

