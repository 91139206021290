import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import courseImage from "../Imgs/course-image.jpg";
import BlockImg1 from "../Imgs/BlockImg1.png";
import RoadMap from "../Imgs/Roadmap.png";
import TreeNetwork from "../Imgs/TreeNetwork.png";
import ThreeCircles from "../Imgs/ThreeCircles.png";
import SingleCircle from "../Imgs/SingleCircle.png";
import TwoCircles from "../Imgs/2Circles.png";
import Cylinder from "../Imgs/Cylinder.png";
import EthTree2 from "../Imgs/EthTree2.png";
import "./Home.css";

function Home({ isLoggedIn }) {

  const styles = {
    backgroundColor: '#161524',
    width:"100%",
  };

  const paddingStyle = {
    height: '100px',
  };

  return (
    <div style={styles}>
      <Row>
        <img src={BlockImg1} style={{ width:"100%"}} />
      </Row>
      <Row className = "my-5 d-flex text-center align-items-center smoke-text-title">
        <div style={paddingStyle}></div>
        <p>Welcome Dev</p>
        <div style={paddingStyle}></div>
      </Row>

      <Row className ="my-3">
        <Col xs={12} md={6} className="text-center d-flex justify-content-center align-items-center">
          <img src={TreeNetwork} style = {{width:"100%"}}/>
        </Col>
        <Col xs={12} md={6} className="my-5 d-flex text-center align-items-center smoke-text">
          <p>Blockchain technology is revolutionizing the way we handle online transactions. Join our Ethereum community to learn about smart contract auditing and become part of the movement to secure the future of online transactions.</p>
        </Col>
      </Row>

      <div style={paddingStyle}></div>
      <div style={paddingStyle}></div>
      <div style={paddingStyle}></div>

      <Row className ="my-3">
        <Col xs={12} md={6} className="my-5 d-flex text-center align-items-center smoke-text">
          <p>Trust is the cornerstone of any successful business. In the world of blockchain technology, security is the key to establishing trust. Join us to gain an in-depth understanding of blockchain security and how to audit smart contracts.</p>
        </Col>
        <Col xs={12} md={6} className="text-center d-flex justify-content-center align-items-center">
          <img src={RoadMap} style = {{width:"100%"}}/>
        </Col>
      </Row>

      <div style={paddingStyle}></div>
      <div style={paddingStyle}></div>
      <div style={paddingStyle}></div>

      <Row className = "my-5 d-flex text-center align-items-center smoke-text-title">
        <p>Let's get started.</p>
      </Row>

      <div style={paddingStyle}></div>
      <div style={paddingStyle}></div>

      <Container>
        <Row style={{
          display:'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
          gridGap: '1rem'
        }}>
            <Card className="p-3">
              <Card.Img variant="top" src={courseImage} />
              <Card.Body>
                <Card.Title>Blockchain Basics</Card.Title>
                <Card.Text>
                  Our comprehensive course will teach you everything you need
                  to know to become a blockchain expert. Learn the core
                  mechanisms of the blockchain and develop a strong foundation
                  to become a great smart contract auditor.
                </Card.Text>
                  <Link to="">
                    <div className="mt-auto">
                      <Button variant="primary" disabled title="Coming  Soon">Coming Soon.</Button>
                    </div>
                  </Link>
              </Card.Body>
            </Card>

            <Card className="p-3">
              <Card.Img variant="top" src={courseImage} />
              <Card.Body>
                <Card.Title>Starting Solidity</Card.Title>
                <Card.Text>
                  Let's set you up with hardhat and other smart contract
                  auding tools so that you can learn solidity like a boss.
                  Then, lets make some simple contracts.
                </Card.Text>
                  <Link to="">
                    <div className="mt-auto">
                      <Button variant="primary" disabled title="Coming  Soon">Coming Soon.</Button>
                    </div>
                  </Link>
              </Card.Body>
            </Card>

            <Card className="p-3">
              <Card.Img variant="top" src={courseImage} />
              <Card.Body>
                <Card.Title>Intermediate Solidity</Card.Title>
                <Card.Text>
                  Now that we have the basics out of the way, lets make
                  some token ecosystems! Lets go over ERC20 tokens, NFTs,
                  factories, and more complex DeFI applications.
                </Card.Text>
                  <Link to="">
                    <div className="mt-auto">
                      <Button variant="primary" disabled title="Coming  Soon">Coming Soon.</Button>
                    </div>
                  </Link>
              </Card.Body>
            </Card>

            <Card className="p-3">
              <Card.Img variant="top" src={courseImage} />
              <Card.Body>
                <Card.Title>Advanced Solidity</Card.Title>
                <Card.Text>
                  Lets get in the weeds. Become a keccak master, learn about
                  proxy contracts, upgradability, libraries, governance, and
                  more unique DeFI wizardry.
                </Card.Text>
                  <Link to="">
                      <div className="mt-auto">
                      <Button variant="primary" disabled title="Coming  Soon">Coming Soon.</Button>
                    </div>
                  </Link>

              </Card.Body>
            </Card>
            <Card className="p-3">
              <Card.Img variant="top" src={courseImage} />
              <Card.Body>
                <Card.Title>Assembly</Card.Title>
                <Card.Text>
                  Well, I said we got in the weeds, but I lied. Now we are
                  really going to get dirty. Are you prepared?
                </Card.Text>
                <Link to="">
                    <div className="mt-auto">
                      <Button variant="primary" disabled title="Coming  Soon">Coming Soon.</Button>
                    </div>
                  </Link>

              </Card.Body>
            </Card>

            <Card className="p-3">
              <Card.Img variant="top" src={courseImage} />
              <Card.Body>
                <Card.Title>Oracles</Card.Title>
                <Card.Text>
                  Lets learn chainlink with an applicable exercise layout.
                  Build some great token ecosystems with me. What do you
                  want to build?
                </Card.Text>
                <Link to="">
                    <div className="mt-auto">
                      <Button variant="primary" disabled title="Coming  Soon">Coming Soon.</Button>
                    </div>
                  </Link>

              </Card.Body>
            </Card>

        </Row>
      </Container>

      <div style={paddingStyle}></div>
      <div style={paddingStyle}></div>
    </div>
  );
}

export default Home;

